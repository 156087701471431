import React from "react"
import '@styles/components/price-table.scss'

export default ({className}, props) => (
  <div class={`price-table ${className}`}>
    <table>
      <tr>
        <th>学年</th>
        <th>月4回授業コース</th>
        <th>月額料金</th>
      </tr>
      <tr>
        <td rowspan="2">小学生</td>
        <td>通常 60分</td>
        {/* <td><span>24,000円</span>⇒<span>16,000円</span></td> */}
        <td><span>24,000円</span></td>
      </tr>
      <tr>
        <td>受験対策 60分</td>
        {/* <td><span>32,000円</span>⇒<span>24,000円</span></td> */}
        <td><span>32,000円</span></td>
      </tr>
      <tr>
        <td rowspan="2">中学生</td>
        <td>通常 90分</td>
        {/* <td><span>30,000円</span>⇒<span>24,000円</span></td> */}
        <td><span>30,000円</span></td>
      </tr>
      <tr>
        <td>受験対策 90分</td>
        {/* <td><span>36,000円</span>⇒<span>28,000円</span></td> */}
        <td><span>36,000円</span></td>
      </tr>
      <tr>
        <td rowspan="2">高校生</td>
        <td>通常 90分</td>
        {/* <td><span>36,000円</span>⇒<span>28,000円</span></td> */}
        <td><span>36,000円</span></td>
      </tr>
      <tr>
        <td>受験対策 90分</td>
        {/* <td><span>45,000円</span>⇒<span>32,000円</span></td> */}
        <td><span>45,000円</span></td>
      </tr>
    </table>
  </div>
)