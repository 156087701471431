import React from "react"
import { Link } from "gatsby"
import RightArrowIcon from "@images/svg/icon-right-arrow_white_solid.svg"
import '@styles/components/teacher-link.scss'

export default ({className}, props) => (
  <article class={`teacher-link ${className}`}>
    <div class="teacher-link__container">
      <h2 class="heading2 accent01">講師紹介</h2>
      <p class="txt-lh_175">一部講師のプロフィールを公開しています。<br />※HPに掲載していない講師もいます。詳しくは初回面談にてご紹介させていただきます。</p>
      <p class="btn btn--primary btn--primary--white"><Link to="/teachers">講師一覧をみる<RightArrowIcon /></Link></p>
    </div>
  </article>
)