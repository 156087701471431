import React,{useState,useEffect}from "react"
import {graphql ,Link } from "gatsby"
import Layout from "@components/layout"
import Image from "@components/image"
import Meta from "@components/meta"
import TeacherLink from "@components/teacher-link"
import PriceTable from "@components/price-table"
import NewsItem from "@components/news-item"
import BottomCta from "@components/bottom-cta"
import RightArrowIcon from "@images/svg/icon-right-arrow_white_solid.svg"
import LinkPlusIcon from "@images/svg/icon-linkPlus.svg"
import '@styles/pages/index.scss'
import posed, { PoseGroup } from 'react-pose'
const transition = {
  visible: { opacity: 1,delay: 600, },
  hidden: { opacity: 0,delay: 600}
}
const Box = posed.div(transition)

const IndexPage = (props) => {
  const [path, setPath] = useState(false);
  const [isFirst, setIsFirst] = useState(true);
  useEffect(()=> {
    if(isFirst) { // 初回実行時
      setIsFirst(false);
    } else {
      setPath(true);
      return ()=> setPath(false);
    }
  })
  
  const createNewsList = () => {
    let newsArray = [];
    for(let i = 0; i < 2; i++) {
      newsArray.push(props.data.allMicrocmsNews.edges[i]);
    }
    return newsArray;
  }
  return (
      <Layout key={props.location.pathname}>
        <Meta
          desc="KnockLearn"
        />
        <PoseGroup>
          <Box key={"index"} pose={path ? "visible" : "hidden"}>
          <section class="key-visual">
            <div class="key-visual__container">
              <div class="left">
                <h1 class="key-visual__catch">難関校出身社会人講師によるマンツーマン型オンライン家庭教師サービス</h1>
                <p class="txt-lh_225">KnockLearnは難関校出身で現在も様々な分野で活躍する社会人講師が、ワンランク上の授業をお届けするオンライン家庭教師サービスです。</p>
                <p class="btn btn--primary">
                    <Link to="/contact">
                      申し込みはこちら<RightArrowIcon />
                      </Link>
                </p>
                {/* <p class="bg-text">KnockLearn</p> */}
              </div>
              <Image className="right" filename="img-keyVisual_pc.png" alt="女子学生が勉強している" />
              {/* <img class="right" src={keyVisual} alt={"a"} /> */}
            </div>
          </section>

          <section class="section our-service">
            <div class="section__container">
              <Image className="teacher-image" filename="img-ourService_pc.png" alt="笑顔の女性講師" />
              
              <div class="our-service__wrapper">
                <div>
                  <p class="heading2-lead">サービス内容</p>
                  <h2 class="heading2">なんのための勉強？<br />その問いに答えられる教育を。</h2>
                </div>
                <p class="txt-lh_255--serif">KnockLearnでは難関校に合格できる勉強習慣や解き方をお子様へお伝えし、目指す進路の実現をお手伝いします。<br class="tb_none"/>また「勉強ができる」だけではなく、「社会で活躍できる」人間性や思考法のトレーニングも提供しています。</p>
              </div>
            </div>
          </section>
          
          <section class="success-stories">
          <Image className="desk-study-image" filename="img-successStory01_pc.png" alt="笑顔の女性講師" />
            <div class="">
              <div class="success-stories__wrapper">
                <div class="left">
                  <p class="heading2-lead">目標達成したお子さまのストーリー</p>
                  <h2 class="heading2">半年間で定期テスト合計100点以上UPし、自主的な学習習慣が付いたSくん</h2>
                  <p class="txt-lh_200">勉強に対する意欲があまりなかったSくんですが、サービス利用後社会人講師とディスカッションを行う「モチベーション教育」を通して今取り組んでいる勉強が社会にどう活かされているか知ることができ、学ぶ楽しさを見つけることができました。<br/><br/>また難関国立大学出身の社会人講師が行なっていた勉強習慣や解き方を身につけることで定期テストの点数は大幅に向上しました。</p>
                </div>
                <div class="right">
                  <Image className="skun1" filename="img-skun1.png" alt="笑顔の女性講師" />
                  <p>中学3年生S君 [福岡市在住]</p>
                </div>
              </div>
              <div class="success-stories__wrapper">
                <div class="left">
                  <h3 class="heading3">S君が初めて見つけた人生の先輩と、<br class="tb_none"/>将来の目標</h3>
                  <p class="txt-lh_200">Sくん「これまでは何のために勉強をしているか分からず勉強が嫌いだったが、先生から勉強が社会でどのように役立つか体験談を交えて聞けたことで将来どうなりたいか方向性が見えてきて、勉強が楽しくなった。学校では教えてくれない社会の話が面白く、自分も先生のような人になりたいと感じた。」</p>
                  <p class="btn btn--link">
                    <Link to="/teachers">
                        <LinkPlusIcon /><span>講師一覧をみる</span>
                    </Link>
                  </p>
                </div>
                <Image className="skun2" filename="img-skun2.png" alt="笑顔の女性講師" />
              </div>
            </div>
          </section>
          
          <section id="service" class="section flow">
            <div class="section__container">
              <p class="heading2-lead">授業モデル</p>
              <h2 class="heading2">他塾が実現できないワンランク上の授業</h2>
              <p class="txt-lh_225 gray01">初回面談の際にご希望に沿った授業内容をご提案いたします。</p>
              <div class="flow__container">
                <section class="flow-item">
                  <div class="flow-item__wrap">
                    <p class="num">01</p>
                    <div>
                      <h3>モチベーション教育<span>(冒頭10分)</span></h3>
                      <p class="txt-lh_200">お子様が興味を持ったニュースをもとに社会経験豊富な講師とディスカッションを行い、探究心・思考力を養います。</p>
                    </div>
                  </div>
                  <Image className="flow-item__image" filename="img-flow01_pc.png" alt="" />
                </section>
                <section class="flow-item">
                  <div class="flow-item__wrap">
                    <p class="num">02</p>
                    <div>
                      <h3>授業<span>(50分/80分)</span></h3>
                      <p class="txt-lh_200">英語・数学・理科・国語・社会などお子様のニーズに合わせて、目標達成・志望校合格に向けた指導を行います。難関校出身の講師だからこそ教えることのできる学校では習えない勉強習慣やテクニックをお伝えいたします。</p>
                    </div>
                  </div>
                  <Image className="flow-item__image" filename="img-flow02_pc.png" alt="" />
                </section>
                {/* <div class="flow-flex">
                  <section class="flow-item flow-item--column">
                    <div class="flow-item__wrap">
                      <p class="num">02</p>
                      <div>
                        <h3>生徒主導のディスカッション<span>（10分）</span></h3>
                        <p class="txt-lh_200">お子様が興味を持ったニュースについて、生徒主導のディスカッションを行い、主体性・実行力を養います。</p>
                      </div>
                    </div>
                    <Image className="flow-item__image" filename="img-flow02_pc.png" alt="" />
                  </section>
                  <section class="flow-item flow-item--column">
                    <div class="flow-item__wrap">
                      <p class="num">03</p>
                      <div>
                        <h3>教科学習<span>（40分）</span></h3>
                        <p class="txt-lh_200">英語・数学・理科・国語・社会など生徒様のニーズに沿って、基礎学習を行います</p>
                      </div>
                    </div>
                    <Image className="flow-item__image" filename="img-flow03_pc.png" alt="" />
                  </section>
                </div> */}
              </div>
            </div>
          </section>

          <section class="section strength">
            <div class="section__container">
              <p class="heading2-lead">選ばれる3つの強み</p>
              <h2 class="heading2">今だから始めたい、「一生もの」の社会教育を。</h2>
              <section class="strength-item">
                <div class="strength-item__wrap">
                  {/* <p class="heading3-lead">強み1</p> */}
                  <h2 class="heading3">難関校出身社会人から教わる<br/>目標達成・志望校合格までの近道。</h2>
                  <p class="txt-lh_200">難関校出身の先生にしか分からないスキルやノウハウを余すことなくお子様へお伝えします。<br/>現在のお子様の立ち位置から最短で目標達成ができる「近道」を一緒に考えていきます。</p>
                </div>
                <Image className="strength-item__image" filename="img-strength01_pc.png" alt="" />
              </section>
              <section class="strength-item reverse">
                <div class="strength-item__wrap">
                  {/* <p class="heading3-lead">強み2</p> */}
                  <h2 class="heading3">リアルな社会との接点で、<br/>世の中への探究心を。</h2>
                  <p class="txt-lh_200">KnockLearnの講師は難関校出身の社会人限定。<br/>学校では聞くことのできないリアルな社会の仕組みや面白さを、冒頭10分間のモチベーション教育にて講師の実体験を交えながらお伝えします。<br/>社会に興味を持ち、その世界に踏み入れるためには自分が何を今すべきなのかという「教科書に書かれていないこと」を知ることで、学習意欲や好奇心を刺激します。</p>
                </div>
                <Image className="strength-item__image" filename="img-strength02_pc.png" alt="" />
              </section>
              <section class="strength-item">
                <div class="strength-item__wrap">
                  {/* <p class="heading3-lead">強み3</p> */}
                  <h2 class="heading3">人生の講師となれる、<br/>経験豊富な講師陣</h2>
                  <p class="txt-lh_200">日系大手〜ベンチャー・スタートアップ勤務、起業家など様々なバックグラウンドを持った講師がお子様に寄り添います。<br/>また豊富な社会経験だけでなく、厳しい選考を通過した講師のみ採用しておりますので授業の質もご安心ください。</p>
                </div>
                <Image className="strength-item__image" filename="img-strength03_pc.png" alt="" />
              </section>
            </div>
          </section>

          <TeacherLink className="index"/>

          <section id="price" class="section price">
            <div class="section__container">
              <p class="heading2-lead">PRICE</p>
              <h2 class="heading2">料金表</h2>
              <PriceTable />
              <p class="gray01">※ 指導時間や回数はご要望に合わせて変更可能です。</p>
            </div>
          </section>

          <section id="news" class="section news">
            <div class="section__container">
              <div>
                <p class="heading2-lead headingBg1">NEWS</p>
                <h2 class="heading2">お知らせ</h2>
                <p class="btn btn--small btn--small--solid">
                  <Link to="/news">
                    <span>一覧を見る</span><RightArrowIcon />
                  </Link>
                  </p>
              </div>
              <ul>
                  {createNewsList().map(( {node} ) => (
                    <NewsItem id={node.newsId} key={node.newsId} link={node.newsId} datetime={node.updatedAt} txt={node.title}/>
                  ))}
              </ul>
            </div>
          </section>

          <BottomCta />
          </Box>
      </PoseGroup>
      </Layout>
  )
}
export default IndexPage

export const query = graphql`
query {
  allMicrocmsNews {
    edges {
      node {
        id
        title
        updatedAt
        body
        newsId
      }
    }
  }
}
`