import React from "react"
import { Link } from "gatsby"
import '@styles/components/news-item.scss'
import RightArrowIcon from "@images/svg/icon-right-arrow_white_solid.svg"

export default (props) => {
  const datetime = props.datetime.slice( 0, 10 ) ;

  return (
    <li key={props.key} class={`news-item ${props.className ? props.className : ""}`}>
      <Link to={`/news/${props.id}`}>
          <div>
              <time datetime={datetime}>{datetime}</time><span>{props.txt}</span>
          </div>
          <RightArrowIcon/>
      </Link>
    </li>
  )
  
}